import React from 'react'
import * as styles from '../../pages/styles/main.module.scss'
import * as mainArticles from './mainArticles.module.scss'
import {StaticImage} from 'gatsby-plugin-image'
import { changeColor } from '../../source/changeColor'


const DescriptionImage = ({dataBlock}) => {

    return(
            <div className={`${styles.container} ${styles.relative} ${mainArticles.company}`} data-sal='slide-up' data-sal-delay='100'>
                <div className={styles.sectionTitle}>
                    <h2>{changeColor(dataBlock.Naglowek ,2)}</h2>
                    <p>{dataBlock.Tekst}</p>
                </div>  
                <div className={mainArticles.imageTexts}>
                    <StaticImage src={'../../images/partner.png'} alt="Aliatan - rzetelny partner Twojej firmy" className={mainArticles.imageBlock} />
                        <p >{dataBlock.Tekst_2}</p>
                </div>
             </div>
           
      
    )
}

export default DescriptionImage;