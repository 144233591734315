// extracted by mini-css-extract-plugin
export var imageTexts = "mainArticles-module--imageTexts--1exx0";
export var imageBlock = "mainArticles-module--imageBlock--3iiea";
export var company = "mainArticles-module--company--3V_9v";
export var whatsUs = "mainArticles-module--whatsUs--3AIFf";
export var blocks = "mainArticles-module--blocks--DitIe";
export var block = "mainArticles-module--block--31NA7";
export var certificats = "mainArticles-module--certificats--t8mBA";
export var aliceCarousel__prevBtn = "mainArticles-module--alice-carousel__prev-btn--2XBx7";
export var modalImage = "mainArticles-module--modalImage--3hu_0";
export var close = "mainArticles-module--close--2-MiO";