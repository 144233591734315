import React from 'react'
import * as styles from '../../pages/styles/main.module.scss'
import * as banner from './banner.module.scss'

const Banner = ({dataBlock}) => {
    return(
        <div className={banner.bannerBlock}>
            <div className={`${styles.container} ${styles.relative} ${styles.h100}`}>
                        <div className={banner.text}>
                            <h2>{dataBlock.Naglowek}</h2>
                        <p>{dataBlock.Tekst}</p>
                        <a href="/oferta" className={`${styles.btn} ${styles.btn__headerGreen}`}>{dataBlock.przycisk}</a>
                    </div>
           
                
            </div>
        </div>
    )
}

export default Banner;

