import * as React from "react"
import Banner from "../components/banner/banner"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import DescriptionImage from "../components/mainArticles/descriptionImage"
import WhatsUs from "../components/mainArticles/whatsUs"
import Certyficats from "../components/mainArticles/certyficats"
import { Helmet } from "react-helmet"


const IndexPage = ({data}) => {
  const allAirtableBanner = data.allAirtable.edges;
  const dataBanner = allAirtableBanner;

  return (
    <>
    <Helmet>
       <meta name='description' content='Biuro rachunkowe – dla Twojej firmy. Usługi księgowe, kadrowe i doradztwo podatkowe w oparciu o profesjonalną kadrę.'/>
    </Helmet>
    <Layout pageTitle="main">
      {dataBanner.map((element,index)=>{
       if(index===0){return <Banner dataBlock={element.node.data}/>}
       if(index===1){return <DescriptionImage dataBlock={element.node.data}/>}
       if(index===2){return <WhatsUs dataBlock={element.node.data}/>}
       if(index===3){return <Certyficats dataBlock={element.node.data}/>}
      })}
       
        
    </Layout>
    </>
  )
}


export const query = graphql`
{
  allAirtable(sort: {fields: data___Ids}, filter: {table: {eq: "StronaGlowna"}}) {
    edges {
      node {
        data {
          Ids
          Naglowek
          Sekcja
          Tekst
          przycisk
          Tekst_2
          certyfikaty {
            url
          }
          cecha1
          cecha2
          cecha3
          cecha4
        }
      }
    }
  }
}
`


export default IndexPage
