import React from "react"
import { changeColor } from "../../source/changeColor"
import * as styles from "../../pages/styles/main.module.scss"
import * as whatsUsModule from "./mainArticles.module.scss"
import { StaticImage } from "gatsby-plugin-image"

const WhatsUs = ({dataBlock}) =>{
    return(
    <div className={whatsUsModule.whatsUs} data-sal='slide-up' data-sal-delay='100'>
        <div className={styles.sectionTitle}>
            <h2>{changeColor(dataBlock.Naglowek, 2)}</h2>
        </div>
            <div className={`${whatsUsModule.blocks} ${styles.container}`}>
                <div className={whatsUsModule.block} data-sal='zoom-in' data-sal-delay='200'>
                    <StaticImage src={'../../images/main/order.png'} alt={dataBlock.cecha1}/>
                    <h3>{dataBlock.cecha1}</h3>
                </div>
                <div className={whatsUsModule.block} data-sal='zoom-in' data-sal-delay='300'>
                    <StaticImage src={'../../images/main/money.png'} alt={dataBlock.cecha2}/>
                    <h3>{dataBlock.cecha2}</h3>
                </div>
                <div className={whatsUsModule.block} data-sal='zoom-in' data-sal-delay='400'>
                    <StaticImage src={'../../images/main/hands.png'} alt={dataBlock.cecha3}/>
                    <h3>{dataBlock.cecha3}</h3>
                </div>
                <div className={whatsUsModule.block} data-sal='zoom-in' data-sal-delay='500'>
                    <StaticImage src={'../../images/main/work.png'} alt={dataBlock.cecha4}/>
                    <h3>{dataBlock.cecha4}</h3>
                </div>
            </div>
       
    </div>
    )
}

export default WhatsUs;