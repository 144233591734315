import React,{useState} from 'react'
import { changeColor } from "../../source/changeColor";
import * as styles from "../../pages/styles/main.module.scss"
import * as articles from "./mainArticles.module.scss"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ModalImage from './modalImage';
import '../../pages/styles/style.scss';

const Certyficats = ({dataBlock}) => {

    const [seeCert, setSeeCert] = useState({
        img: ''
    });
    const handleClickCert = e => {
        setSeeCert({
            img: e.currentTarget.src
        })
    }

    const handleCloseModal = click => {
        if(click){
            setSeeCert({
                img: ''
            })
        }
        
    }
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive:[
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
      };
    return(
    <div className={`${styles.container} ${articles.certificats}`} data-sal='slide-up' data-sal-delay='400'>
        <div className={styles.sectionTitle}> 
            <h2>{changeColor(dataBlock.Naglowek)}</h2>
            <p>{dataBlock.Tekst}</p>
        </div>
        <Slider {...settings} className='sliderModal'>
            {dataBlock.certyfikaty.map((element,index) => (
                <img src={element.url} alt={`certyfikat + ${index}`} key={index} onClick={e=>handleClickCert(e)}/>
            ))}
        </Slider>
        {(seeCert.img !=='')?<ModalImage active={seeCert.img} close={ cls=>handleCloseModal(cls)}/>:null}
    </div>
    )
}

export default Certyficats;