import React from 'react'
import * as articles from "./mainArticles.module.scss"

const ModalImage = ({active,close}) => {

    const handleClose = () => {
        return close(true)
    }
    return(
        <div className={articles.modalImage} onClick={()=>handleClose()}>
            <img src={active} alt="certyfikat"/>
            <p className={articles.close}>X</p>
        </div>
    )
}

export default ModalImage;